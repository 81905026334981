<template>
  <div>
    <SettingsNavigation />

    <div
      class="max-w-screen-xl mx-auto sm:flex sm:justify-between px-6"
    >
      <BillingCard class="mb-8 sm:w-1/2 sm:mr-8 sm:mb-0" />
      <InvoicesCard class="mb-8 sm:w-1/2 sm:mr-8 sm:mb-0" />
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'
import BillingCard from '@components/Plans/BillingCard'
import InvoicesCard from '@components/Plans/InvoicesCard'

export default {
  components: {
    SettingsNavigation,
    BillingCard,
    InvoicesCard
  }
}
</script>
